* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

.switch-danger .form-check-input:checked {
    background-color: #dc3545; /* Cor de fundo quando ativado */
    border-color: #dc3545; /* Cor da borda quando ativado */
}

.hide-recaptcha .grecaptcha-badge {
    visibility: hidden;
}

.valign-middle {
    vertical-align: middle;
}

body {
    zoom: 100%;
    overscroll-behavior: contain;
}

#app-container {
    min-height: 100vh;
    display: flex;
}

#app-menu {
    width: 200px;
    transition: width 500ms;
    font-size: 1rem;
}

#app-menu .menu-icon {
    font-size: 1.5rem;
    width: 40px;
}

#app-menu .menu-item {
    margin: 2px 5px;
    height: 40px;
    border-radius: 5px;
}

#app-menu .menu-item:hover {
    background-color: black;
    cursor: pointer;
}

#menu-header {
    height: 60px;
    padding: 5px;
    display: flex;
    align-items: center;
}

#app-menu.closed {
    width: 50px;
}

#app-menu.closed .menu-item {
    justify-content: center !important;
}

#app-menu.closed .hideOnClosed {
    display: none;
}

#app-header {
    margin: 0;
    border: 0;
    padding: 10px;
    box-shadow: 5px 5px 10px 0px #A0A0A0;
}

#app-content {
    flex-grow: 1;
    margin-right: 5px;
}

#app-footer {
    border-radius: 5px;
    margin: 5px 5px 5px 0;
    padding: 5px;
}

@media screen and (max-width: 992px) {
    #app-menu {
        width: 50px;
    }

    #app-menu .hideOnClosed {
        display: none;
    }
}

.app-card {
    box-shadow: 10px 10px 10px 0px #E0E0E0;
}

.card-item {
    font-size: 1.5rem;
    box-shadow: 10px 10px 10px 0px #E0E0E0;
}

.card-item:hover {
    cursor: pointer;
}

.app-calendar {
    width: 100%;
    border-radius: 10px;
    border: none;
    box-shadow: 5px 5px 15px 0px #E0E0E0;
}

.app-aviso {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0px #E0E0E0;
}

.app-form {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0px #E0E0E0;
}

label.force-required::after,
label.required:has(~ textarea:placeholder-shown:required)::after,
label.required:has(~ :required[value=""])::after,
label.required:has(~ select:required option[value=""]:checked)::after {
    content: " *";
    color: red;
    font-weight: bold;
}

.card-dashboard {
    border-left: solid 10px transparent !important;
}

.card-dashboard-active {
    border-left: solid 10px orangered !important;
}


#lp-frame {
    top: 10%;
    left: 15%;
    min-height: 80%;
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 25px;
    box-shadow: 10px 10px 10px 0px #E0E0E0;
}

#lp-frame-1 {
    border-radius: 25px 0 0 25px;
    flex: 1 1;
    padding: 25px;
}

#lp-frame-2 {
    width: 40%;
    border-radius: 0 25px 25px 0;
    padding: 25px;
}

@media (max-width: 992px) {
    #lp-frame-1 {
        display: none;
    }

    #lp-frame-2 {
        border-radius: 25px;
        width: 100%
    }
}

@media print {

    @page {
        margin: 1cm;
        /* Remova as margens padrão */
    }

    .container {
        min-width: 100%;
    }

    .collapse:not(.show) {
        display: block;
    }

    * {
        font-size: 8pt;
        box-shadow: none !important;
        color: black !important;
    }

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        float: left;
    }

    .col-lg-1 {
        width: 8%;
    }

    .col-lg-2 {
        width: 16%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-4 {
        width: 33%;
    }

    .col-lg-5 {
        width: 42%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-7 {
        width: 58%;
    }

    .col-lg-8 {
        width: 66%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-10 {
        width: 83%;
    }

    .col-lg-11 {
        width: 92%;
    }

    .col-lg-12 {
        width: 100%;
    }
}